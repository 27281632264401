function increaseProjectViewCount() {
  // 外に置くとグローバル関数扱いになり、
  // この処理より後に定義された同名関数に処理を奪われる可能性があるのでネストした
  function getParam(key) {
    const url = window.location.href;
    const num = url.indexOf('?');
    const queryString = url.slice(num + 1);
    const params = queryString.split('&');
    let value = '';
    for (let i = 0; i < params.length; i += 1) {
      const [paramKey, paramValue] = params[i].split('=');
      if (paramKey === key) {
        value = paramValue;
        break;
      }
    }
    return value;
  }

  if (!getParam('token')) {
    return;
  }
  $.ajax({
    type: 'POST',
    url: `/api/share/${getParam('token')}/view`,
    cache: false,
    dataType: 'json',
    data: { project_id: $('#project_id').val() },
  });
}

// コーチマークの位置調整
function returnCoachMark() {
  if (!$.cookie('is_return_coach_mark')) {
    $('.return-coach-mark').css('display', 'inline-block');
    if ($('#returnTitleText').text().trim() === '寄附する') {
      $('.return-coach-mark').css('margin-left', '17.6px');
    }
  }
  $('.return-question-text-link,.return-coach-mark-close,.return-question').on(
    'click',
    () => {
      $.cookie('is_return_coach_mark', true, { expires: 10 * 365, path: '/' });
      $('.return-coach-mark').css('display', 'none');
    },
  );
}

// コーチマーク、キービジュアル右「応援購入する」、PCスクロール時左側追従の「応援購入する」、「文章のTOPへ」クリック時に発火
// グローバル関数として window に登録する
window.onSupportPurchase = function onSupportPurchase() {
  let className = '.returnTtl';
  if ($('#return-question-coach').css('display') === 'block') {
    className = '.returnTtl1';
  }

  $('html,body').animate({ scrollTop: $(className).offset().top - 20 }, 800);
  return false;
};

$(() => {
  window.Geenie.Watched.set_cookie();
  increaseProjectViewCount();
  returnCoachMark();
});
